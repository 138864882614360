exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-callback-jsx": () => import("./../../../src/pages/callback.jsx" /* webpackChunkName: "component---src-pages-callback-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-my-brackets-jsx": () => import("./../../../src/pages/my-brackets.jsx" /* webpackChunkName: "component---src-pages-my-brackets-jsx" */),
  "component---src-pages-user-user-[id]-bracket-id-create-jsx": () => import("./../../../src/pages/user/[userId]/bracket/[id]/create.jsx" /* webpackChunkName: "component---src-pages-user-user-[id]-bracket-id-create-jsx" */),
  "component---src-pages-user-user-[id]-bracket-id-fill-jsx": () => import("./../../../src/pages/user/[userId]/bracket/[id]/fill.jsx" /* webpackChunkName: "component---src-pages-user-user-[id]-bracket-id-fill-jsx" */),
  "component---src-pages-user-user-[id]-bracket-id-index-jsx": () => import("./../../../src/pages/user/[userId]/bracket/[id]/index.jsx" /* webpackChunkName: "component---src-pages-user-user-[id]-bracket-id-index-jsx" */)
}

